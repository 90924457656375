import { render, staticRenderFns } from "./userPropsList.vue?vue&type=template&id=17d9519d&scoped=true"
import script from "./userPropsList.vue?vue&type=script&lang=js"
export * from "./userPropsList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.8_cache-loader@4.1.0_webpack@4.47.0__css-loader@3.6._mcqzgzcvcfvdrickecrqkz6mje/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17d9519d",
  null
  
)

export default component.exports